import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesOrden, actualizarStatus } from '../../actions/ordenes';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10, textAlign: 'justify' },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    bordeDer: {
        borderRight: '1px solid #0000001a'
    },
    botonArchivos: {
        background: 'transparent',
        boxShadow: '0px 0px 0px transparent',
        border: '0px solid transparent',
        textShadow: '0px 0px 0px transparent'
    },
    btnStatus: {
        cursor: 'pointer',
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    progressbar: {
        paddingLeft: '0px'
    }
}

const OrdenDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay resultados a mostrar</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const orden = useSelector(state => state.ordenes);
    
    const [ordenModal, setOrdenModal] = useState({
        show: false,
        showInput: false,
        showButton: false,
        texto: '',
        textoButton: ''
    });

    const [ordenData, setOrdenData] = useState({
        id: '',
        status: 0,
        costo: '-'
    });

    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });


    const handleOrden = (status) => {
        switch(status){
            case 0:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: false, textoButton: '', texto: 'El gerente de zona debe de preautorizar la orden primero. Una vez hecho esto, podrás complementar la orden.'});
                break;

            case 1:
                setOrdenModal({...ordenModal, show: true, showInput: true, showButton: true, textoButton: 'Autorizar', texto: 'El gerente ya preautorizó la orden, ahora debes de complementarla con el costo de la misma y actualizar el status.'});
                break;

            case 2:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: false, textoButton: '', texto: 'Ya complementaste la orden, ahora debes de esperar a que el gerente de el visto bueno final.'});
                break;

            case 3:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: true, textoButton: 'Finalizar', texto: 'El gerente ya autorizó la orden, puede comenzar a trabar en ella. Una vez que termines, deberas de "Finalizarla".'});
                break;

            case 4:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: false, textoButton: '', texto: 'La orden ya ha sido entregada y finalizada.'});
                break;

            case 5:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: false, textoButton: '', texto: 'La orden fue cancelada.'});
                break;

            default:
                setOrdenModal({...ordenModal, show: true, showInput: false, showButton: false, textoButton: '', texto: ''});
                break;
        }
    }

    const changeOrden = () => {
        dispatch(actualizarStatus({id: ordenData.id, status: ordenData.status + 1, costo: ordenData.costo}));
        setOrdenData({ ...ordenData, status: ordenData.status + 1 });
        setOrdenModal({...ordenModal, show: false, showButton: false, textoButton: '', texto: ''});
    }

    const onChangeCosto = (e) => {
        setOrdenData({
            ...ordenData,
            costo: e.target.value
        });
    }

    useEffect(() => {
        dispatch(detallesOrden(id));
    }, [id]);

    useEffect(() => {
        setOrdenData({
            ...ordenData,
            id: orden.detalles?._id,
            status: orden.detalles?.status,
            costo: orden.detalles?.costo == '' ? '-' : orden.detalles?.costo
        });

        setCoord({
            lat: parseFloat(orden.detalles?.latitud_sucursal),
            lng: parseFloat(orden.detalles?.longitud_sucursal)
        });
    }, [orden]);

    const columnsProductos = [
        {
            name: '',
            sortable: false,
            cell: item => <img src={item.imagen} className="img-fluid picture-micro image-btn" onClick={() => setImageData({ ...imageData, modal: true, src: item.imagen })} alt=""/>
        },
        {
            name: 'Código de barras',
            sortable: true,
            cell: item => item.codigo_barras ? item.codigo_barras : '-',
        },
        {
            name: 'Nombre',
            sortable: true,
            cell: item => item.nombre ? item.nombre : '-',
        },
        {
            name: 'Descripción',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        },
        {
            name: 'Cantidad',
            sortable: true,
            cell: item => item.cantidad ? item.cantidad : '-',
        }
    ]

    const columnsAcciones = [
        {
            name: 'Fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const colorOrden = (status) => {
        switch(status){
            case 0:
            case 2:
            case 3:
                return 'warning';
                break;

            case 1:
                return 'primary';
                break;

            case 4:
                return 'success';
                break;

            case 5:
                return 'danger';
                break;

            default:
                return '';
                break;
        }
    }

    const statusReminder = (status) => {
        switch(status){
            case 0:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleOrden(0)}>Pendiente de preautorización</Badge>;
                break;

            case 1:
                return <Badge variant="primary" style={styles.btnStatus} onClick={() => handleOrden(1)}>Preautorizada</Badge>;
                break;

            case 2:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleOrden(2)}>Pendiente de autorización</Badge>;
                break;

            case 3:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleOrden(3)}>Pendiente de finalización</Badge>;
                break;

            case 4:
                return <Badge variant="success" style={styles.btnStatus} onClick={() => handleOrden(4)}>Finalizada</Badge>;
                break;

            case 5:
                return <Badge variant="danger">Cancelada</Badge>;
                break;
        }
    }

    return (
        orden.detalles ? <Fragment>

            {/* Modal de órden */}
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={ordenModal.show} onHide={() => {
                setOrdenModal({...ordenModal, show: false, showInput: false, showButton: false, textoButton: '', texto: ''});
                setOrdenData({...ordenData, costo: orden.detalles?.costo == '' ? '-' : orden.detalles?.costo});
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Información de status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ordenData.status != 5 && (
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <ul id="progressbar" style={styles.progressbar}>
                                    <li className={ordenData.status >= 0 ? 'active' : ''} id="iniciada-li"><strong>Iniciada</strong></li>
                                    <li className={ordenData.status >= 1 ? 'active' : ''} id="preautorizada-li"><strong>Preautorizada</strong></li>
                                    <li className={ordenData.status >= 2 ? 'active' : ''} id="complementada-li"><strong>Complementada</strong></li>
                                    <li className={ordenData.status >= 3 ? 'active' : ''} id="autorizada-li"><strong>Autorizada</strong></li>
                                    <li className={ordenData.status >= 4 ? 'active' : ''} id="finalizada-li"><strong>Finalizada</strong></li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <p style={styles.info}>{ordenModal.texto}</p>

                    {ordenModal.showInput && (
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Costo:</label> <br></br>
                                <input type="number" className="form-control" placeholder="Costo a cobrar" name="costo" onChange={e => onChangeCosto(e) } value={ordenData.costo}></input>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {ordenModal.showButton && (
                        <Button variant={colorOrden(ordenData.status)} onClick={() => changeOrden()} disabled={isNaN(ordenData.costo) || ordenData.costo == '' || ordenData.costo < 0 ? true : false}>
                            {ordenModal.textoButton}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                  />
            )}

            <div className="row px-5 pt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{orden.detalles.productos ? orden.detalles.productos.length : 0}</p>
                                    <p style={styles.infoText}>cantidad de productos</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}><Badge variant="primary">{orden.detalles.folio}</Badge></p>
                                    <p style={styles.infoText}>folio</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{statusReminder(ordenData.status)}</p>
                                    <p style={styles.infoText}>status</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Detalles de sucursal</h5>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <div className="mapa">
                                        <GoogleMap
                                            mapContainerStyle={{ width: '100%', height: '25vh' }}
                                            center={coord}
                                            zoom={16}
                                        >
                                            <Marker position={coord} />
                                        </GoogleMap>
                                    </div>
                                </div>
                                <div className="col-md-6 row">
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Nombre</p>
                                        <p style={styles.info}>{orden.detalles.nombre_sucursal ? orden.detalles.nombre_sucursal : '-' }</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p style={styles.subtitulo}>Dirección</p>
                                        <p style={styles.info}>{orden.detalles.direccion_sucursal ? orden.detalles.direccion_sucursal : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Gerente</p>
                                        <p style={styles.info}>{orden.detalles.nombre_gerente ? orden.detalles.nombre_gerente : '-' }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{orden.detalles.telefono_gerente ? orden.detalles.telefono_gerente : '-' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Detalles de la orden</h5>
                            <div className="row">
                                <div className="col-md-12 row">
                                    <div className="col-md-3">
                                        <p style={styles.subtitulo}>Costo</p>
                                        <p style={styles.info}>{
                                            ordenData.costo == '-' ? ordenData.costo : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ordenData.costo)
                                        }</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p style={styles.subtitulo}>Creador</p>
                                        <p style={styles.info}>{orden.detalles.nombre_creador ? orden.detalles.nombre_creador : '-' }</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p style={styles.subtitulo}>Fecha de creación</p>
                                        <p style={styles.info}>{new Date(orden.detalles.fecha_creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p style={styles.subtitulo}>Fecha de finalización</p>
                                        <p style={styles.info}>{orden.detalles.fecha_finalizacion != null ? new Date(orden.detalles.fecha_finalizacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <p style={styles.subtitulo}>Productos</p>
                                        <div style={styles.sectionContainer}>
                                            <DataTable
                                                columns={columnsProductos}
                                                data={orden.detalles.productos}
                                                defaultSortField="title"
                                                noHeader={true}
                                                pagination
                                                paginationComponentOptions={paginationOptions}
                                                noDataComponent={noDataConst}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div style={styles.sectionContainer}>
                                <DataTable
                                    columns={columnsAcciones}
                                    data={orden.detalles.historial_status}
                                    defaultSortField="title"
                                    noHeader={true}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    noDataComponent={noDataConst}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default OrdenDetalles;
