import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadDashboard } from '../../actions/dashboard'

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#2C2052' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 20,
        margin: 0,
        fontWeight: 'bold'
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    buttonIcon: {
        fontSize: 30,
    },
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    },
}

const Dashboard = () => {

    const proveedor = useSelector(state => state.auth.proveedor)
    const dashboard = useSelector(state => state.dashboard)
    const perfil = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDashboard());
    }, [])

    return (
        dashboard.info ? <div className="row">
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-10 offset-md-1 mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row mt-4">
                            <div className="col-md-12 d-flex align-items-center mb-3">
                                <img src={perfil.proveedor.url_perfil_p != null ? perfil.proveedor.url_perfil_p : (perfil.proveedor.sexo == 'm' ? '/assets/images/placeholder.png' : '/assets/images/placeholder.png')} className="picture-min" id="wizardPicturePreview" title=""/>
                                <h4 style={styles.titulo} className="ml-3">
                                    Bievenido {proveedor.nombre}
                                </h4>
                            </div>
                            <div className="col-md-12">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Indicadores generales</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ganancia_total)
                                                }</p>
                                                <p style={styles.infoText}>ganancia total</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ganancia_mes)
                                                }</p>
                                                <p style={styles.infoText}>ganancia este mes</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.sucursales}</p>
                                                <p style={styles.infoText}>sucursales gestionadas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Órdenes</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.iniciadas}</p>
                                                <p style={styles.infoText}>iniciadas</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.preautorizadas}</p>
                                                <p style={styles.infoText}>preautorizadas</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.complementadas}</p>
                                                <p style={styles.infoText}>complementadas</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.autorizadas}</p>
                                                <p style={styles.infoText}>autorizadas</p>
                                            </div>
                                            <div className="col">
                                                <p style={styles.infoNumero}>{dashboard.info.finalizadas}</p>
                                                <p style={styles.infoText}>finalizadas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></div > : <div></div>
    )
}

export default Dashboard;