import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { setAlert } from '../../actions/alert';
import { login, recuperar, restablecer } from '../../actions/auth';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
    },
    btnIniciar: {
        background: '#AA1E54',
        borderColor: '#AA1E54'
    },
    btnRecuperar: {
        color: '#0a416f'
    }
}

const Login = ({ history }) => {

    const [loginData, setLoginData ] = useState({
        email: '',
        password: ''
    });

    const [recuperarData, setRecuperarData] = useState({
        emailRecuperar: '',
        passwordRecuperar: '',
        codigoRecuperar1: '',
        codigoRecuperar2: '',
        codigoRecuperar3: '',
        codigoRecuperar4: '',
        recuperando: false,
        restableciendo: false,
        cambiando: false
    });

    const { isAuthenticated, codigo } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        if (codigo !== undefined && recuperando === true) {
            handleRestablecer();
        }
    }, [codigo]); 

    // Redireccionamos si está autenticado
    if (isAuthenticated) {
        return <Redirect to="/dashboard"></Redirect>
    }

    const { email, password } = loginData;

    const { emailRecuperar, recuperando, restableciendo, cambiando, codigoRecuperar1, codigoRecuperar2, codigoRecuperar3, codigoRecuperar4, passwordRecuperar } = recuperarData;

    const onChange = (e) => {
        setLoginData({
            ...loginData, [e.target.name]: e.target.value
        })
    }

    const onChangeEmailRecuperar = (e) => {
        setRecuperarData({
            ...recuperarData, [e.target.name]: e.target.value
        })
    }

    const onChangeCodigoRecuperar = (e) => {
        setRecuperarData({
            ...recuperarData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        dispatch(login({ email, password}));
    }

    const submitRecuperar = async (e) => {
        e.preventDefault();
        dispatch(recuperar({ emailRecuperar }));
        if (codigo !== undefined) {
            handleRestablecer();
        }
    }

    const submitRestablecer = async (e) => {
        e.preventDefault();

        if (codigo == (codigoRecuperar1 + codigoRecuperar2 + codigoRecuperar3 + codigoRecuperar4)) {
            dispatch(setAlert('Código correcto', 'success'));
            setRecuperarData({
                ...recuperarData,
                codigoRecuperar1: '',
                codigoRecuperar2: '',
                codigoRecuperar3: '',
                codigoRecuperar4: '',
                recuperando: false,
                restableciendo: false,
                cambiando: true
            });
        }else{
            console.log(codigo);
            console.log(codigoRecuperar1 + codigoRecuperar2 + codigoRecuperar3 + codigoRecuperar4);
            dispatch(setAlert('El código no coincide', 'danger'));
        }
    }

    const submitCambiar = async (e) => {
        e.preventDefault();
        dispatch(restablecer({ emailRecuperar, passwordRecuperar }));

        setRecuperarData({
            ...recuperarData,
            emailRecuperar: '',
            codigoRecuperar1: '',
            codigoRecuperar2: '',
            codigoRecuperar3: '',
            codigoRecuperar4: '',
            passwordRecuperar: '',
            recuperando: false,
            restableciendo: false,
            cambiando: false
        });

        return <Redirect to="/"></Redirect>
    }

    const handleClose = () => {
        setRecuperarData({
            ...recuperarData,
            emailRecuperar: '',
            codigoRecuperar1: '',
            codigoRecuperar2: '',
            codigoRecuperar3: '',
            codigoRecuperar4: '',
            recuperando: false,
            restableciendo: false,
            cambiando: false
        });

        return <Redirect to="/"></Redirect>
    };

    const handleShow = () => {
        setLoginData({ email: '', password: '' })
        setRecuperarData({
            ...recuperarData,
            recuperando: true,
            restableciendo: false,
            cambiando: false
        });
    };

    const handleRestablecer = () => {
        setRecuperarData({
            ...recuperarData,
            recuperando: false,
            restableciendo: true,
            cambiando: false
        });
    };
    
    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <Helmet>
                <style>{'body { background-color: #AA1E54; }'}</style>
            </Helmet>
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <img src="assets/images/logo.png" className="d-inline-block my-3" alt="logo" height="65" />
                        </div>

                        <form id="f-login" onSubmit={submitForm} method="POST" className={ recuperando === true || restableciendo === true || cambiando === true ? 'd-none' : '' }>
                            <div className="form-group">
                                <label className="font-weight-bold">Correo:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold">Contraseña:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-8 form-group text-left">
                                    <button type="button" className="btn btn-link text-info" onClick={handleShow} style={styles.btnRecuperar}>¿No recuerdas tu contraseña?</button>
                                </div>
                                <div className="col-md-4 form-group text-right">
                                    <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Iniciar sesión</button>
                                </div>
                            </div>
                        </form>

                        <form id="f-recuperar" onSubmit={submitRecuperar} method="POST" className={ recuperando === true ? '' : 'd-none' }>
                            <p>Ingresa tu cuenta de correo para reestablecer tu contraseña. Se te enviará un código de 4 digitos a tu correo para este proceso.</p>
                            <div className="form-group">
                                <label className="font-weight-bold">Correo:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo"
                                    name="emailRecuperar"
                                    value={emailRecuperar || ''}
                                    onChange={e => onChangeEmailRecuperar(e)}></input>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-8 form-group text-left">
                                    <button type="button" className="btn btn-link text-info" onClick={handleClose} style={styles.btnRecuperar}>Iniciar sesión</button>
                                </div>
                                <div className="col-md-4 form-group text-right">
                                    <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Envíar código</button>
                                </div>
                            </div>
                        </form>

                        <form id="f-restablecer" onSubmit={submitRestablecer} method="POST" className={ restableciendo === true ? '' : 'd-none' }>
                            <div className="form-group text-center">
                                <label className="font-weight-bold">Código:</label><br/>
                            </div>
                            <div className="form-group text-center">
                                <input
                                    type="text"
                                    className="input-codigo"
                                    name="codigoRecuperar1"
                                    min="0"
                                    min="9"
                                    maxLength="1"
                                    value={ codigoRecuperar1 || ''}
                                    onChange={e => onChangeCodigoRecuperar(e)}></input>
                                <input
                                    type="text"
                                    className="input-codigo"
                                    name="codigoRecuperar2"
                                    min="0"
                                    min="9"
                                    maxLength="1"
                                    value={ codigoRecuperar2 || ''}
                                    onChange={e => onChangeCodigoRecuperar(e)}></input>
                                <input
                                    type="text"
                                    className="input-codigo"
                                    name="codigoRecuperar3"
                                    min="0"
                                    min="9"
                                    maxLength="1"
                                    value={ codigoRecuperar3 || ''}
                                    onChange={e => onChangeCodigoRecuperar(e)}></input>
                                <input
                                    type="text"
                                    className="input-codigo"
                                    name="codigoRecuperar4"
                                    min="0"
                                    min="9"
                                    maxLength="1"
                                    value={ codigoRecuperar4 || ''}
                                    onChange={e => onChangeCodigoRecuperar(e)}></input>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-8 form-group text-left">
                                    <button type="button" className="btn btn-link text-info" onClick={handleClose} style={styles.btnRecuperar}>Iniciar sesión</button>
                                </div>
                                <div className="col-md-4 form-group text-right">
                                    <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Recuperar cuenta</button>
                                </div>
                            </div>
                        </form>

                        <form id="f-cambiar" onSubmit={submitCambiar} method="POST" className={ cambiando === true ? '' : 'd-none' }>
                            <div className="form-group">
                                <label className="font-weight-bold">Nueva contraseña:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="passwordRecuperar"
                                    value={passwordRecuperar}
                                    onChange={e => onChangeEmailRecuperar(e)}></input>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-8 form-group text-left">
                                    <button type="button" className="btn btn-link text-info" onClick={handleClose} style={styles.btnRecuperar}>Iniciar sesión</button>
                                </div>
                                <div className="col-md-4 form-group text-right">
                                    <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Cambiar contraseña</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;