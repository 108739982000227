import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, RECUPERAR, RESTABLECER } from './types';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

// LOAD USER
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/prove_auth/`);
        dispatch({
            type: USER_LOADED,
            payload: res.data.proveedor
        })
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })
    }

}

// LOGIN USER
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password});
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/prove_auth/`, body, config);
        dispatch(setAlert('Sesión iniciada correctamente', 'success'));
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// Recuperar Contraseña
export const recuperar = ({ emailRecuperar }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        email: emailRecuperar
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/prove_auth/recuperar`, body, config);
        dispatch({
            type: RECUPERAR,
            payload: res.data.codigo
        });

        dispatch(setAlert('El código fue enviado correctamente.', 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// Modificar contraseña
export const restablecer = ({ emailRecuperar, passwordRecuperar }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        email: emailRecuperar,
        password: passwordRecuperar
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/prove_auth/restablecer`, body, config);
        dispatch({
            type: RECUPERAR
        });

        dispatch(setAlert('Contraseña modificada correctamente.', 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// Logout
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}