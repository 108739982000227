import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Layout
import NotFoundPage from './components/routing/NotFoundPage'
import PrivateRoute from './components/routing/PrivateRoute'
import Layout from './components/layout/Layout';
import Alert from './components/layout/Alert'

// Login
import Login from './components/auth/Login'
import Dashboard from './components/dashboard/Dashboard'

// Ordenes
import Ordenes from './components/ordenes/Ordenes'
import OrdenesFinalizadas from './components/ordenes/OrdenesFinalizadas'
import OrdenDetalles from './components/ordenes/OrdenDetalles'

// Configuración
import Configuracion from './components/configuracion/Configuracion'

// Redux
import { Provider } from 'react-redux';
import setAuthToken from './utils/setAuthToken';
import store from './store';
import { loadUser } from './actions/auth'


const App = () => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])


    return (<Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <section className="container-fluid" style={{ paddingLeft: '64px' }}>
                    <Alert></Alert>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* Ordenes */}
                        <PrivateRoute exact path="/ordenes" component={Ordenes} />
                        <PrivateRoute exact path="/ordenes/finalizadas" component={OrdenesFinalizadas} />
                        <PrivateRoute exact path="/ordenes/detalles/:id" component={OrdenDetalles} />

                        {/* Configuración */}
                        <PrivateRoute exact path="/configuracion" component={Configuracion} />

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>)
}

export default App;
