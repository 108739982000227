import React, { useEffect, useState, createRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { setAlert } from '../../actions/alert';

import { guardarPerfil } from '../../actions/configuracion';

registerLocale('es', es);

const Configuracion = ({ history }) =>{

    const dispatch = useDispatch();

    const perfil = useSelector(state => state.auth);
    
    const [perfilData, setPerfilData ] = useState({
        nombre: '',
        contacto: '',
        telefono: '',
        email: '',
        password: '',
        titular: '',
        cuenta: '',
        clabe: '',
        banco: '',
        imagen: ''
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    let img = createRef();

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: perfil.proveedor?._id,
            nombre: perfil.proveedor?.nombre,
            contacto: perfil.proveedor?.contacto,
            telefono: perfil.proveedor?.telefono,
            email: perfil.proveedor?.email,
            titular: perfil.proveedor?.datos_bancarios?.titular || '',
            cuenta: perfil.proveedor?.datos_bancarios?.cuenta || '',
            clabe: perfil.proveedor?.datos_bancarios?.clabe || '',
            banco: perfil.proveedor?.datos_bancarios?.banco || '',
            imagen: perfil.proveedor?.url_perfil_g
        })
    }, [perfil]);

    const onChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    const onChangeImg = () => {
        setProfilePicture({
            ...profilePicture, img: img.current.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        try {
            formData.append('nombre', perfilData.nombre);
            formData.append('contacto', perfilData.contacto);
            formData.append('telefono', perfilData.telefono);
            formData.append('email', perfilData.email);
            formData.append('password', perfilData.password);
            formData.append('titular', perfilData.titular);
            formData.append('cuenta', perfilData.cuenta);
            formData.append('clabe', perfilData.clabe);
            formData.append('banco', perfilData.banco);
            formData.append('perfil', profilePicture.img);
            dispatch(guardarPerfil(formData));
        } catch (error) {
            return false;
        }
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Información personal</h3>
                <div className="row mb-3">
                    <div className="col-12 form-group">
                        <div className="picture-container">
                            <div className="picture">
                                <img src={profilePicture.img != null ? URL.createObjectURL(profilePicture.img) : perfilData.imagen} className="picture-src" id="wizardPicturePreview" title=""/>
                                <input type="file" name="image" id="image" ref={img} onChange={onChangeImg} accept="image/*"/>
                            </div>
                            <p className="icon bg-purple">+</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Nombre:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={perfilData.nombre}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Persona de contacto:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contacto" name="contacto" onChange={e => onChange(e)} value={perfilData.contacto}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Teléfono de contacto:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Teléfono" name="telefono" onChange={e => onChange(e)} value={perfilData.telefono}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email de acceso" name="email" onChange={e => onChange(e)} value={perfilData.email}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña de acceso" name="password" onChange={e => onChange(e)} value={perfilData.password}></input>
                    </div>
                </div>

                <h3 className="mb-3 mt-5">Datos bancarios</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Titular de la cuenta:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Titular" name="titular" onChange={e => onChange(e)} value={perfilData.titular}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Número de cuenta:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Cuenta" name="cuenta" onChange={e => onChange(e)} value={perfilData.cuenta}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Clabe interbancaria:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Clabe" name="clabe" onChange={e => onChange(e)} value={perfilData.clabe}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Banco:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Banco" name="banco" onChange={e => onChange(e)} value={perfilData.banco}></input>
                    </div>
                </div>

                <button type="submit" className="btn btn-success float-right mb-4" onClick={handleSubmit}>Guardar perfil</button>
            </form>
        </div>
    )
}

export default Configuracion;