// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const EDIT_PERFIL = 'EDIT_PERFIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const RECUPERAR = 'RECUPERAR';
export const RESTABLECER = 'RESTABLECER';

// DASHBOARD
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';

// ORDENES
export const LOAD_ORDENES = 'LOAD_ORDENES';
export const LOAD_FINALIZADAS = 'LOAD_FINALIZADAS';
export const DETAIL_ORDEN = 'DETAIL_ORDEN';
export const LOAD_ACCION = 'LOAD_ACCION';

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
