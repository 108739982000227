import React from 'react';
import { withRouter } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '@fortawesome/fontawesome-free/css/all.css';


const Sidebar = ({ history, location }) => {

    const styles = {
        navStyles: { fontSize: '1rem', color: '#EEE', whiteSpace: 'nowrap' },
        submenuStyle: { width: '15rem' }
    }
    return (
        <SideNav
            style={{ backgroundColor: '#AA1E54', position: 'fixed' }}
            onSelect={(selected) => {
                if (location.pathname !== selected) {
                    history.push(selected);
                }
            }}
        >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="/dashboard">
                <NavItem eventKey="/dashboard">
                    <NavIcon>
                        <i className="fa fa-home" style={styles.navStyles} />
                    </NavIcon>
                    <NavText style={styles.navStyles}>
                        Inicio
                    </NavText>
                </NavItem>
                <NavItem subnavStyle={styles.submenuStyle}>
                    <NavIcon>
                        <i className="fa fa-shopping-cart" style={styles.navStyles} />
                    </NavIcon>
                    <NavText style={styles.navStyles}>
                            Órdenes
                    </NavText>
                    <NavItem eventKey="/ordenes">
                        <NavText>
                            En proceso
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="/ordenes/finalizadas">
                        <NavText>
                            Finalizadas
                        </NavText>
                    </NavItem>
                </NavItem>
                <NavItem eventKey="/configuracion">
                    <NavIcon>
                        <i className="fa fa-cogs" style={styles.navStyles} />
                    </NavIcon>
                    <NavText style={styles.navStyles}>
                        Configuración
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>

    )
}

export default withRouter(Sidebar);
