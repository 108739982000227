import { EDIT_PERFIL } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const guardarPerfil = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/prove_configuracion/editar-perfil`, perfil, config);
        dispatch(changeLoader(false));
        dispatch({
            type: EDIT_PERFIL,
            payload: res.data.proveedor
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}